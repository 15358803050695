@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600');

//colors
$app-main-text-color: #333;
$board-bg-color: #f0f1f2;
$line-color: #ddd;
$color-default: #0E71B9;
$color-text: #000;
$color-dashboard: #d6d6d6;
$color-discount: #d3574c;

//size
$gap: 10px;
$font-size-default: 14px;
$size-item-header: 20px;
$size-discount: 30px;
$body-height: 50vh ;
$header-height:10vh;
$footer-height : 40vh;