@media screen and (min-width: 1400px) {
  .hover_button_tracuu {
    margin-left: 4px;
  }
  .list-thuonghieu {
    margin-bottom: 6px;
    width: 28%;
  }
}
@media screen and (min-width:601px) and (max-width: 767px) {
  .hotline{
    display: none !important;
  }

}
@media screen and (min-width:1200px) and (max-width: 1399px) {

  body {
    .list-items-button {
      .item-action {
        width: calc((100% / 3) - 100px);
        margin: 40px 40px 0;
      }
    }
    .search-page {
      ul {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
    .col-md-7.hidden-xs {
      .col-md-5, .col-md-7 {
        width: 50%;
        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width:600px)  {
  .csbh_image_banner{
    display: none;
  }
  .hover_button_tracuu {
 
    margin-top: 4px;
  }
  .login_with_daily{
    .col-md-4 {
    width: 100% !important;
    }
  }
  .title_home{
    font-size: 12px;
  }
  .header_content_title{
    display: none !important;
  }
  .title_home_content{
    font-size: 12px !important;
  }
  .hotline{
    display: none !important;
   
  }
  .logo-desktop img {
    width: 100px !important; 
 
  }
  .header_content_title{
    display: none !important;
  }
 
  .chinh-sach{
    font-size: 20px;
    line-height: 1.2;
  }
  .icon_tracuu {
    position: relative;
    display: grid;
    justify-items: center;
    grid-template-rows: auto auto;
  }
  
  .icon_tracuu .item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon_tracuu .item.first-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .icon_tracuu .item.second-row {
    grid-row: 2;
    justify-self: center;
    align-self: center;
  }
  .filter_date_tracuutinhtrang{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .content.row.progress_step{
    display: none;
  }
  .title_important_header{
    display: block;
  }
  .responsive_input{
    display: flex;
    flex-direction: column;
  }
  .responsive_serial{
    display: flex;
    flex-direction: column;
  }
  .col-md-3.responsive_checkbox,.col-md-4.responsive_checkbox, .col-md-8.responsive_checkbox{
    display: flex;
    width: 100% !important;
  }
  .col-md-3.responsive_checkbox{
    margin-bottom: 20px;
  }
  .title_yeucau{
    width: 100%;
  }
  .city{
    width: 100%;
  }
}
@media screen and (min-width: 1291px) {
  .icon_tracuu{
    position: absolute;
    right: -20%;
    bottom: 80%;
     display: flex;
      flex-direction: column;
       height: 10%
  }
  .responsive_serial{
    display: flex;
    flex-direction: row;
  }
 .header_content_title{
    display: none !important;
  }
  .icon_tracuu .item.first-row{
    display: flex;
    flex-direction: column;
  }
 
 
}
@media screen and (min-width:760px) and (max-width: 1290px) {
  .chinh-sach{
    font-size: 26px;
    line-height: 1.2;
  }
 
 .header_content_title{
    display: none !important;
  }
  
  .content.row.progress_step{
    display: none;
  }

  .responsive_input{
    display: flex;
    flex-direction: column;
  }

  .col-md-2.responsive_checkbox,.col-md-3.responsive_checkbox,.col-md-4.responsive_checkbox, .col-md-8.responsive_checkbox,
  .col-md-10.responsive_checkbox{
    display: flex;
    width: 100% !important;
  }
  .col-md-3.responsive_checkbox{
    margin-bottom: 20px;
  }
  .title_yeucau{
    width: 100%;
  }
  .city{
    width: 100%;
  }
  .csbh_image_banner{
    display: none;
  }
}



@media screen and (min-width:992px) and (max-width: 1199px) {
  .icon_tracuu {
    position: relative;
    display: grid;
    justify-items: start;
    grid-template-rows: auto auto;
  }
  .title_home_content{
    font-size: 12px;
  }
  
 .header_content_title{
    display: none !important;
  }
  .logo-desktop{
    display: none !important;
  }
  .icon_tracuu .item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon_tracuu .item.first-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .icon_tracuu .item.second-row {
    grid-row: 2;
    justify-self: center;
    align-self: center;
  }
  body {
    .list-items-button {
      .item-action {
        width: calc((100% / 3) - 100px);
        margin: 40px 40px 0;
      }
    }
    
    .name-title {
      font-size: 80px !important;
      letter-spacing: 15px;
    }
    .border-vertical {
      height: 50px;
    }
    .search-page {
      ul {
        li {
          padding: 5px !important;
          a {
            font-size: 12px;
          }
        }
        i {
          width: 30px !important;
          height: 30px !important;
          padding: 5px !important;
          line-height: 15px;
        }
      }
    }
    .col-md-7.hidden-xs {
      .col-md-5, .col-md-7 {
        width: 100%;
        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
        }
      }
    }
  }
  .kich-hoat, .yeu-cau {
    .col-md-8.mt-4 {
      width: 90%;
    }
  }
}
@media screen and (min-width: 992px) {
  
  .logo-desktop {
    display: block;
  }
  .logo-desktop img{
    width: 100px;
    height: 30px;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
 .header_content_title{
    display: none !important;
  }
  .logo-mobile {
    display: none;
  }
  .hidden-md {
    display: none;
  }
  .hidden-xs {
    display: block;
  }
 
  .icon_tracuu .item.first-row{
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 991px) {
  .header {
    height: 450px !important;
  }
  .hidden-md {
    display: block;
  }
 .header_content_title{
    display: none !important;
  }
  .hidden-xs {
    display: none;
  }
  .img-product {
    width: 100% !important;
    height: 100% !important;
  }
  .detail-product {
    h5 {
      font-size: 16px;
    }
  }
  .kich-hoat, .yeu-cau {
    .col-md-8, .col-md-4 {
      width: 100%;
    }
  }
}

@media screen and (min-width:768px) and (max-width: 991px) {
  .search-page .list-navbar-items ul li a , .hotline {
    font-size: 9px !important;
  }  
  .logo-desktop {
    display: none;
  }
  .product-selector { 
    .banner-image {
      h2 {
         top: 68% !important;
         left: 15% !important;
         text-align: center;
      }
    }
  }
  .phukien_filter{
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important; 
    width: 100% !important;
    gap: 10px !important;
  }
  .img-product-selector{
    height: 124px !important;
  }
  .filter_product{
    display: flex;
    flex-direction: column;
    align-items: start !important;
    margin: 10px 0;
  }
  .card{
    display: none !important;
  }
  .card_mobile{
    display: flex !important;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .res_pc{
    display: none !important;
  }
 .search-page{
  max-width: 800px !important;
 }
 .header_content_title{
    display: none !important;
  }
  .logo-mobile {
    display: block;
  }
  .icon_tracuu{
    display: flex;
    justify-content: space-evenly;
  }
  .responsive_serial{
    display: flex;
    flex-direction: column;
  }
  .hover_button_tracuu{
    margin-top: 4px;
  }
  body {
    .list-items-button {
      .item-action {
        width: calc((100% / 3) - 30px);
        margin: 30px 15px 0;
      }
    }
    .content-detail {
      .col-md-5 {
        width: 100%;
      }
      .col-md-7 {
        width: 100%;
      }
    }
  
    .name-title {
      font-size: 60px !important;
      letter-spacing: 15px;
    }
    .border-vertical {
      height: 40px !important;
      &::after {
        top: -30px !important;
      }
    }
    .search-page {
      ul {
        li {
          padding: 3px 6px!important;
          a {
            font-size: 12px;
          }
        }
      }
    }
    .w-20.col {
      display:none ;
    }
    .search-header.w-60 {
      width: 72%;
      padding: 0;
    }
    .header-col-set.w-20.col-3 {
      width: 8%;
    }
  }
  .product-item-selector {
    .list-item-selector {
      flex-basis: 50% !important;
      max-width: 50% !important;
      .card-product-selector {
        a.d-item {
          img {
            min-height: 170px !important;
          }
        }
        .info-product {
          .info-detail {
      
            h5 {
              a {
                font-size: 0.8rem !important;
              }
            }
          }
          .box-detail {
            margin: 5px 0 !important;
            a.item-news {
              padding: 0.5rem 0.2rem !important;
              font-size: 14px !important;
              p {
                span {
                  display: none;
                }
              }
            }
            a.border-0 {
              width: 2rem !important;
              height: 2rem !important;
              span {
                font-size: 16px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .phukien_filter{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important; 
    width: 100% !important;
    gap: 10px !important;
  }
  .list-thuonghieu{
    width: 100%;
  }
  .img-product-selector{
    height: 124px !important;
  }
  .filter_product{
    display: flex;
    flex-direction: column;
    align-items: start !important;
    margin: 10px 0;
  }
  .card{
    display: none !important;
  }
  .card_mobile{
    display: flex !important;
    margin-bottom: 10px;
    
  }
  .res_pc{
    display: none !important;
  }
  .product-item-selector {
    .list-item-selector {
      flex-basis: 50% !important;
      max-width: 50% !important;
      .card-product-selector {
        a.d-item {
          img {
            min-height: 220px !important;
          }
        }
        .info-product {
          .info-detail {
          
            h5 {
              a {
                font-size: 1rem !important;
              }
            }
          }
          .box-detail {
            margin: 5px 0 !important;
            a.item-news {
              padding: 0.5rem 0.2rem !important;
              font-size: 14px !important;
            }
            a.border-0 {
              width: 2rem !important;
              height: 2rem !important;
              span {
                font-size: 16px !important;
              }
            }
          }
        }
      }
    }
   
  }
  .navbar-header {
    z-index: 99;
    // position: relative;
  }
  .logo-desktop {
    display: inline-block;
  }
  .logo-mobile {
    display: none;
  }
 .header_content_title{
    display: none !important;
  }
  .list-items-button {
    .item-action {
      width: calc((100% / 2) - 30px) !important;
      margin: 30px 15px 0 !important;
    }
  }
  .search-page {
    ul {
      li {
        padding: 3px !important;
        a {
          font-size: 12px;
        }
      }
    }
    ul.list-data {
      float: right;
      right:30px;
      position: relative;
    }
    .navbar-toggler {
      position: absolute;
      right: 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .list-navbar-items {
      background-color: #fff;
      left: 0;
      position: absolute;
      z-index: 22;
      top: 60px;
      .list-unstyled {
        display: block !important;
        margin: 0;
        li {
          padding: 0 !important;
          a {
            cursor: pointer;
            vertical-align: middle;
            padding: 10px;
            width: 100%;
            display: inline-block;
            color: $color-default !important;
          }
        }
      }
    }
  }
  .col-md-4 {
    width: 40% !important;
  }
  .col-md-8 {
    width: 60% !important;
  }
  
  .name-title {
    font-size: 60px !important;
    letter-spacing: 15px;
  }
  .border-vertical {
    height: 40px !important;
    &::after {
      top: -30px !important;
    }
  }
  .w-20.col {
    display:none ;
  }
  .search-header.w-60 {
    width: 60%;
    ul {
      li {
        display: none;
      }
      li.tra-cuu {
        display: block;
      }
    }
  }
  .header-col-set.w-20.col-3 {
    width: 40%;
  }
  .detail-product {
    .my-4 {
      margin: 0 !important;
    }
    .col-md-5 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .footer  {
    background-color: #fff;
    position: relative;
    .col-md-3 {
      width: 50%;
    }
    .footer_flex {
      display: block !important;
      width: 100% !important;
    }
  }
  .tra-cuu {
    .container {
      height: auto !important;
    }
    h6 {
      margin-bottom: 20px ;
    }
    .justify-content-center {
      input {
        width: 75% !important;
      }
    }
  }
  .kich-hoat, .yeu-cau {
    .col-md-8, .col-md-4 {
      width: 100% !important;
    }
  }
  .tram-bao-hanh {
    .content {
      display: block !important;
    }
  }
}

@media screen and (min-width:1020px) and (max-width: 1368px) {
 .icon_tracuu {
    position: relative;
    display: flex;
    justify-content: space-evenly;
  }
  .product-selector { 
    .banner-image {
      h2 {
         top: 62% !important;
         left: 24% !important;
         text-align: center;
      }
    }
  }
 .header_content_title{
    display: none !important;
  }
   
  .hover_button_tracuu {
    margin-left: 4px;
  }
  .list-thuonghieu{
      width: 46%;
  }
}

@media screen and (max-width: 480px) {
  .product-selector { 
    .banner-image {
      h2 {
         top: 77% !important;
         left: 18% !important;
         text-align: center;
      }
    }
    .product-item-selector {
      .list-item-selector {
        flex-basis: 50% !important;
        max-width: 50% !important;
        .card-product-selector {
          a.d-item {
            img {
              min-height: 170px !important;
            }
          }
          .info-product {
            .info-detail {
        
              h5 {
                a {
                  font-size: 0.8rem !important;
                }
              }
            }
            .box-detail {
              margin: 5px 0 !important;
              a.item-news {
                padding: 0.5rem 0.2rem !important;
                font-size: 14px !important;
                p {
                  span {
                    display: none;
                  }
                }
              }
              a.border-0 {
                width: 2rem !important;
                height: 2rem !important;
                span {
                  font-size: 16px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .icon_tracuu {
    position: relative;
    display: grid;
    justify-items: center;
    grid-template-rows: auto auto;
  }
  .hover_button_tracuu.tracuu_serial{
    margin-top: 4px;
  }
  .header_content_title{
    display: none !important;
  }
  .search-page {
    .navbar-header {
      padding-left: 20px;
    }
    .header-col-set {
      a {
        img {
          width: 150px !important;
        }
      }
      ul {
        li.tra-cuu {
          a {
            font-size: 12px;
          }
        }
      
      }
    }
    ul {
      li {
        i {
          font-size: 14px !important;
          width: 30px !important;
        }
      }
    }
  
  }
  .list-items-button {
    .item-action {
      .box-content {
        img {
          width: 40px !important;
          height: 40px !important;
        }
        p {
          font-size: 12px;
      
        }
      }
    }
  }
  .content-text {
    p {
      font-size: 12px;
      text-align: left;
    }
  }
.content-text{
    font-size: 24px;
  }
  .giga-electric {
    .bg-giga.d-flex {
      width: 100%;
      display: inline-block !important;
      .header-top {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .name-title {
      font-size: 24px !important;
      letter-spacing: 5px;
    }
    .header {
      height: 300px !important;
      background-attachment: local;
  }
  .form-control.w-50 {
    width: 75% !important;
  }
    .border-vertical {
      height: 10px !important;
      width: 30px !important;
      &::after {
        top: -5px !important;
        border-left: 3px solid #27bdde66;
      }
    }
  }
  .kich-hoat, .yeu-cau {
    height: auto !important;
    margin-bottom: 30px !important;
    .justify-content-between {
      .col-md-6 {
        width: 100% !important;
      }
    }
 
  }
  .thoiHanBaoHanh {
    fieldset {
      padding: 10px !important;
      .form-group {
        margin-bottom: 0 !important;
      }
    }
    .mb-2.mt-2.d-flex.justify-content-center {
      text-align: center;
      display: block !important;
      input {
        width: 100% !important;
      }
      button {
        margin-top: 15px;
      }
    }
  }
}
