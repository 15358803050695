.footer{
   border-top: 1px solid #ddd;
   background-color: #fff;
   padding:47px 0;
   position: relative;
   .footer_flex{
      display: flex;
      justify-content: space-around;
   }
   .container{
      .col-md-6 {
         h5 {
            position: relative;
            &::before {
               content: '';
               position: absolute;
               left: 0;
               bottom: -5px;
               background-color: $color-default;
               height: 2px;
               box-sizing: border-box;
               width: 50px;
            }
         }
         ul {
            li {
               i {
                  font-size: 18px;
                  color: $color-default;
                  width: 20px;
                  font-weight: 900 !important;
                  font-family: Font Awesome\ 5 Free !important;
               }
            }
         }
      }
   }


}

